<template>
    <div v-if="templates && !isLoading">
        <b-table
            class="table-striped overflow-x-scroll"
            :data="templates"
            :striped="true"
            :mobile-cards="true"
            :sticky-header="false"
        >
            <b-table-column
                sortable
                field="form"
                :label="$t('template.tab.form')"
                v-slot="props"
            >
                {{ props.row.form && props.row.form.name ? props.row.form.name : " "  }}
            </b-table-column>

            <b-table-column
                field="name"
                :label="$t('template.tab.name')"
                v-slot="props"
                sortable
            >
                {{ props.row.response_title ? props.row.response_title : " " }}
            </b-table-column>

            <b-table-column field="" :label="$t('forms.actions')">
                <div
                    class="flex flex-row space-x-2"
                    slot="default"
                    slot-scope="props"
                >
                    <router-link
                        :to="`/admin/templates/${props.row.id}/edit`"
                        class="inline-flex items-center px-3 py-2 ml-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                        </svg>
                    </router-link>
                    <a
                        @click.prevent="deleteTemplate(props.row.id)"
                        class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                        <i class="fa fa-trash text-white h-4 w-4"></i>
                    </a>
                </div>
            </b-table-column>
        </b-table>
        <TemplatesPagination
            v-if="pagination"
            :total-results="pagination.total"
            :total-pages="Math.ceil(pagination.last_page)"
            :per-page="config.per_page"
            :current-page='pagination.current_page'
            @pagechanged="onPageChange"
        />
        <DeleteTemplate ref="deleteTemplate" :template="selectedTemplate" />
    </div>
</template>

<script>
import DeleteTemplate from './DeleteTemplate'
import { mapActions, mapState } from 'vuex'
import TemplatesPagination from '@/components/Model/Admin/Template/TemplatesPagination'

export default {
    name: 'TabTemplates',
    components: {
        DeleteTemplate,
        TemplatesPagination
    },
    props :["isLoading"],
    data: function () {
        return {
            selectedTemplate: {},
        }
    },
    methods: {
        ...mapActions('admin', ['getTemplates']),
        deleteTemplate(id) {
            this.templates.forEach((template) => {
                if (template.id === id) this.selectedTemplate = template
            })
            this.$refs.deleteTemplate.trigger()
        },
        onSort(field, order) {
            this.config.page = 1
            this.config.sort = field
            this.config.sortDirection = order
            this.$emit('load-data')
        },
        onPageChange(page) {
            this.config.page = page
          this.$emit('load-data')
        }
    },
    computed: {
        ...mapState('admin', {
            templates: (state) => state.templates,
            config: (state) => state.templatesConfig,
            pagination: (state) => state.pagination
        })
    },
    created() {
      this.$emit('load-data')
    }
}
</script>
