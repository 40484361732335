<template>
    <bs-modal type="modal-dialog-centered" :id="id">
        <div v-if="template" class="p-4">
            <div class="text-xl">
                {{ $t('delete.are_you_sure') }}
                {{ $t('template.designation').toLowerCase() }}
                <span class="font-bold">{{ template.response_title }}</span> ?
            </div>
        </div>
        <template #footer v-if="template">
            <div class="flex inline-flex justify-center items-center space-x-5">
                <button
                    @click.prevent="cancel"
                    type="button"
                    class="inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    {{ $t('template.delete.cancel') }}
                </button>
                <button
                    @click.prevent="deleteOneTemplate"
                    type="button"
                    class="inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                    {{ $t('template.delete.delete') }}
                </button>
            </div>
        </template>
    </bs-modal>
</template>

<script>
import BsModal from '@/components/UI/BsModal'
import ModalMixin from '@/components/UI/ModalMixin'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'DeleteTemplate',
    mixins: [ModalMixin],
    data: function () {
        return {
            id: 'deleteTemplate'
        }
    },
    props: ['template'],
    components: {
        BsModal
    },
    methods: {
        ...mapActions('admin', ['deleteTemplate']),
        trigger: function () {
            this.modal.show()
        },
        cancel: function () {
            this.modal.hide()
        },
        deleteOneTemplate() {
            this.deleteTemplate(this.template).then(() => {
                this.cancel()
            })
        }
    },
    mounted() {
        this.create(this.id, {
            keyboard: false
        })
    },
    computed: {
        ...mapState('admin', {
            templates: (state) => state.templates
        })
    }
}
</script>
