<template>
    <div v-if="!loading && !isLoading" class="flex justify-between flex-row w-1/5">
        <div
            class="w-full flex flex-col lg:flex-row items-center lg:items-top mt-1"
        >
            <div class="my-1 px-2 w-full">
                <label
                    for="template"
                    class="block text-sm font-medium text-gray-700 mb-1"
                >
                    {{ $t('post.page.postsfilters.form') }}
                </label>
                <multiselect
                    v-model="filterForm"
                    :options="forms"
                    :multiple="true"
                    :close-on-select="true"
                    :select-label="value"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :show-labels="false"
                    selectLabel="Entrée pour ajouter"
                    deselectLabel="Entrée pour enlever"
                    tagPlaceholder="Créer un nouveau tag"
                    selectedLabel="Sélection"
                    @input="
                        onFilterChangeMultiSelect('form_id', filterForm, true)
                    "
                >
                    <span slot="noResult">{{
                        $t('post.page.postsfilters.noresult')
                    }}</span>
                </multiselect>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
    name: 'TemplatesFilters',
  props:['isLoading'],
    components: {
        Multiselect
    },
    data() {
        return {
            value: '',
            filterForm: [],
            loading: true,
            setSelectedTemplate: {},
            filters: {},
            currentFilter: {}
        }
    },
    computed: {
        ...mapState('admin', {
            config: (state) => state.templatesConfig,
            template: (state) => state.template
        }),
        ...mapState('form', {
            forms: (state) => state.forms
        })
    },
    created() {
        this.getForms().then(() => {
            this.loading = false
        })
    },
    methods: {
        ...mapActions('admin', ['getTemplates', 'getTemplate']),
        ...mapActions('form', ['getForms']),
        getFilters() {
            this.getTemplatesFilters().catch((e) => {
                this.$store.dispatch('dispatchError', e)
            })
        },
        onFilterChangeMultiSelect(element, filter, change) {

          if (filter.length) {

            var transformedFilter = Array()
                for (let i = 0; i < filter.length; i++) {
                    transformedFilter[i] = filter[i].id
                }
                filter = {
                    [element]: transformedFilter.toString()
                }
            } else {
                filter = {
                    [element]: filter.id
                }
            }
            if (change) {
              this.onFilterChange(filter)
            } else {
                return filter
            }
        },
        onFilterChange(currentFilter) {
          Object.keys(currentFilter).forEach((element) => {
                this.filters[element] = currentFilter[element]
            })
            this.config.per_page = 15
            this.config.page = 1
            this.config.filters = this.filters
          this.$emit('load-data')
        },
    }
}
</script>
