<template>
  <main class='page-content' role='main' v-if='!isLoading'>
    <div class='subheader hidden-lg-up d-flex'>
      <portal to='ariane'>
        <h1 class='subheader-title mb-2 text-blue-600'>
          <i class='fal fa-envelope m-2 text-blue-600'></i>
          {{ $t('template.name') }}
        </h1>
      </portal>
    </div>

    <section

      class='p-3 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white'
    >
      <div class='flex flex-row justify-between'>
        <TemplatesFilters @load-data='loadAsyncData' :isLoading='isLoading' />
        <div class='flex flex-row items-center'>
          <router-link
            to='/admin/template/create'
            class='inline-flex items-center space-x-4 px-4 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              class='w-5'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2'
                d='M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z'
              />
            </svg>
            <div>{{ $t('template.new') }}</div>
          </router-link>
        </div>
      </div>
      <hr />
      <TabTemplates @load-data='loadAsyncData' :isLoading='isLoading' />
    </section>
  </main>
</template>

<script>
import TabTemplates from '@/components/Model/Admin/Template/TabTemplates.vue'
import TemplatesFilters from '@/components/Model/Admin/Template/TemplatesFilters'
import { mapActions } from 'vuex'

export default {
  name: 'Templates',
  components: {
    TabTemplates,
    TemplatesFilters
  },
  data() {
    return {
      isLoading: true
    }
  },
  created() {
    this.loadAsyncData()
  },
  methods: {
    ...mapActions('admin', ['getTemplates']),
    loadAsyncData() {
      this.getTemplates()
        .then(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
